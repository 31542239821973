import React from "react"; 
import { useEffect, useState } from "react"

import { BsArrowUp } from "react-icons/bs";

const ScrollingToTop = () =>{

    const [showButtonScrollTop, setShowButtonScrollTop] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButtonScrollTop(true);
            } else {
                setShowButtonScrollTop(false);
            }
        });
    }, []);

    // This function will scroll the window to the top 
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };
    return(

        <>
        {showButtonScrollTop && (
            <div class="d-grid gap-2 d-md-flex justify-content-md-end fixed ">
                <button onClick={scrollToTop} class="btn-scrol-page-top rounded-circle">
                    <BsArrowUp size="50" />
                </button>
            </div>
        )}
        </>


    );
};

export default ScrollingToTop;