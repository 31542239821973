import React from "react";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';


const NavigationTop = () => {

    return (
        <div>
            {/* <!-- Navigation--> */}
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container className="container px-5">
                    <Navbar.Brand  href="/">Thinness | SI </Navbar.Brand>                   
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                                          
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                            <li className="nav-item"><a className="nav-link" href="/contato">Contato</a></li>
                            <li className="nav-item"><a className="nav-link" href="/">Cliente</a></li>
                            <li className="nav-item"><a className="nav-link" href="/">Portfolio</a></li>
                        </ul>
                        
                    </Navbar.Collapse>

                </Container>

            </Navbar>
        </div>
    );
};
export default NavigationTop;
