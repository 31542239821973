import React from 'react';



import logo from '../../assets/img/LogoThinness.png';
import imgFront from '../../assets/img/frontimg1.png';
import perfil from '../../assets/img/perfil.png';
import impressoras from '../../assets/img/imps.png';
import infra from '../../assets/img/infra.png';
import pcs from '../../assets/img/pcs.png';



const Home = () => {






    return (
        <div>

            {/* <!-- Header--> */}
            <header class="bg-dark py-5">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center justify-content-center">
                        <div class="col-lg-8 col-xl-7 col-xxl-6">
                            <div class="my-5 text-center text-xl-start">
                                <h1> <img className="img-fluid" src={logo} /> </h1>
                                <p class="lead fw-normal text-white-50 mb-4">Prestamos serviços de infraestrutura de rede, manutenção em impressoras e automação de processos, conheça nossos serviços!</p>
                                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                    <a class="btn btn-primary btn-lg px-4 me-sm-3" href="#features">Serviços</a>
                                    <a class="btn btn-outline-light btn-lg px-4" href="https://api.whatsapp.com/send?phone=5561992761664">Entre em contato</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img class="img-fluid rounded-8 my-8" src={imgFront} alt="..." /></div>
                    </div>
                </div>
            </header>

            {/* <!-- Features section--> */}
            <section class="py-5" id="features">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-8 col-xl-6">
                            <div class="text-center">
                                <h2 class="fw-bolder">Serviços</h2>
                                <p class="lead fw-normal text-muted mb-5">Conheça nossos principais serviços prestado atualmente no mercado de informártica. </p>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5">
                            <div class="card h-80 shadow border-0">
                                <img class="card-img-top" src={impressoras} alt="..." />
                                <hr />
                                <div class="card-body p-4">

                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Impressoras</h5></a>
                                    <p class="card-text mb-0">Prestamos manutenção em toda e qualquer impressora laser, jato de tinta, ploter ou impressora termica.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5">
                            <div class="card h-80 shadow border-0">
                                <img class="card-img-top" src={infra} alt="..." />
                                <hr />
                                <div class="card-body p-4">

                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Infraestrutura de Redes</h5></a>
                                    <p class="card-text mb-0">Implantação de servidores Windows, configurações de rede, bloqueios de sites e instalação de rede estruturada.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5">
                            <div class="card h-80 shadow border-0">
                                <img class="card-img-top" src={pcs} alt="..." />
                                <hr />
                                <div class="card-body p-4">
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Manutenção Computadores</h5></a>
                                    <p class="card-text mb-0">Upgrades em computadores e notebooks, formatação e instalação de softwares.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Testimonial section--> */}
            <div class="py-5 bg-light">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-10 col-xl-7">
                            <div class="text-center">
                                <div class="fs-4 mb-4 fst-italic">"Este site foi desenvolvido a fins de obter conhecimento, por fim, acabou sendo utilizado como apresentação da Thinness SI."</div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <img class="rounded-circle me-3" src={perfil} alt="..." />
                                    <div class="fw-bold">
                                        Paulo Henrique
                                        <span class="fw-bold text-primary mx-1">/</span>
                                        CEO
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

 


        </div >
    );
};
export default Home;