import { Route, Switch } from 'react-router-dom';

import Home from "../pages/home";
import Privacy from "../pages/privacy";
import Terms from "../pages/terms";
import Contato from "../pages/contato";



const Router = () => {
    
    return (   

        <Switch>            
            <Route path="/" exact component={Home} /> 
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/contato" exact component={Contato} />           
        </Switch>

    );
};

export default Router;