import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Form } from '@unform/web';

import Input from '../../componets/forms/input';
import TextArea from "../../componets/forms/textArea";

const Contato = () => {
   // const baseURL = 'http://localhost:3333';
   const baseURL = 'https://controll-tns-backend.herokuapp.com';


    function handleSubmite(data) {
        console.log('imprimindo uma data linda');
        console.log(data);
        axios.post(baseURL+'/sendemailpost', data)
            .then(response => console.log(response));
    };

    return (

        <div>
            {/* <!-- Page content--> */}
            <section class="py-5">
                <div class="container px-5">
                    {/* <!-- Contact form--> */}
                    <div class="bg-light rounded-3 py-5 px-4 px-md-5 mb-5">
                        <div class="text-center mb-5">
                            <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-envelope"></i></div>
                            <h1 class="fw-bolder">Entrar em contato</h1>
                            <p class="lead fw-normal text-muted mb-0">Estamos a disposição para te ouvir.</p>
                        </div>
                        <div class="row gx-5 justify-content-center" >
                            <div class="col-lg-8 col-xl-6">
                                <Form onSubmit={handleSubmite} id="contactForm" novalidate>
                                    {/* <!-- Name input--> */}
                                    <div class="form-floating mb-3">
                                        <Input class="form-control" name="nameContato" type="text" placeholder="Enter your name..." required />
                                        <label for="name">Nome completo</label>
                                        <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                                    </div>
                                    {/* <!-- Email address Input--> */}
                                    <div class="form-floating mb-3">
                                        <Input class="form-control" name="emailContato" type="email" placeholder="name@example.com" required />
                                        <label for="email">E-mail</label>
                                        <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                        <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                                    </div>
                                    {/* <!-- Phone number Input--> */}
                                    <div class="form-floating mb-3">
                                        <Input class="form-control" name="phoneContato" type="tel" placeholder="(61) 99456-7890" required />
                                        <label for="phone">Telefone</label>
                                        <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                                    </div>
                                    {/* <!-- Message Input--> */}
                                    <div class="form-floating mb-3">
                                        <TextArea class="form-control" name="messageContato" type="text" placeholder="Enter your message here..."></TextArea>
                                        <label for="message">Menssagem  </label>
                                        <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                                    </div>
                                    <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error ao enviar Menssagem!</div></div>
                                    {/* <!-- Submit Button--> */}
                                    <div class="d-grid"><button class="btn btn-primary btn-lg " type="submit">Enviar</button></div>
                                </Form>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};
export default Contato;