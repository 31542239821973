import React from "react"
import { Link } from 'react-router-dom'

const Footer = () => {

    return (
        
        <div>
           
            <footer class="bg-dark py-4 mt-auto">
                <div class="container px-5">
                    <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                        <div class="col-auto"><div class="small m-0 text-white">Copyright &copy; Paulo Henrique 2021</div></div>
                        <div class="col-auto">

                            <Link class="link-light small" to="/privacy">Privacidade</Link>                            
                            <span class="text-white mx-1">&middot;</span>
                            <Link class="link-light small" to="/terms">Termos</Link>                             
                            <span class="text-white mx-1">&middot;</span>
                            <Link class="link-light small" to="/contato">Contato</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;

