import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import NavigationTop from './componets/navTop';
import Footer from './componets/footer';
import ScrollingToTop from './componets/scrollToTop';

function App() {
  return (

    <BrowserRouter> 
      <NavigationTop />
        <Router />
        <ScrollingToTop/>
      <Footer />   
    </BrowserRouter>
 
  );
}

export default App;
